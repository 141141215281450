import React, { useState, useRef, useEffect } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import Badge from "@material-ui/core/Badge";
import Avatar from "@material-ui/core/Avatar";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import SendIcon from "@material-ui/icons/Send";
import { invokeApi, image_url } from "../../../bl_libs/ApiCalling";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import socketIOClient from "socket.io-client";
import CircularProgress from "@material-ui/core/CircularProgress";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
var moment = require("moment-timezone");
const { v1: uuidv4 } = require("uuid");

// const SOCKET_SERVER_URL = "http://3.212.199.216:4140";
const SOCKET_SERVER_URL = "https://apidev.ppostit.com";
const NEW_CHAT_MESSAGE_EVENT = "NEW_CHAT_MESSAGE_EVENT";
const DELETE_MESSAGE = "DELETE_MESSAGE";
const RECEIVER_DELETE_MESSAGE = "RECEIVER_DELETE_MESSAGE";
const USER_OFFLINE = "USER_OFFLINE";
const USER_ONLINE = "USER_ONLINE";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: 20,
      },
    },
    marginLeft: 10,
  },
  userList: {
    borderRight: "2px solid rgb(0 0 0 / 12%)",
    maxHeight: 450,
    overflowY: "auto",
  },
  message_list: {
    borderRight: "2px solid rgb(0 0 0 / 12%)",
    maxHeight: 420,
    overflowY: "scroll",
  },
  stick_list: {
    position: "-webkit-sticky",
    position: "sticky",
    top: -12,
    zIndex: 2,
    backgroundColor: "white",
  },
  message: { backgroundColor: "#d8d433", padding: 5, marginBottom: 30 },
  simple_message: {
    backgroundColor: "rgb(0 0 0 / 5%)",
    padding: 5,
    marginBottom: 25,
    marginTop: 25,
  },
  button: {
    color: "white",
    marginLeft: 10,
    marginRight: 10,
    borderRadius: 25,
    maxHeight: 40,
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "#d8d433",
    },
  },
}));
//badge style
const StyledBadgeonline = withStyles((theme) => ({
  badge: {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}))(Badge);

//style bafge offline
const StyledBadgeofline = withStyles((theme) => ({
  badge: {
    backgroundColor: "#dddddd",
    color: "#dddddd",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}))(Badge);

export default function Chat() {
  const classes = useStyles();
  const socketRef = useRef();
  const chatContainer = React.useRef(null);
  const matches = useMediaQuery("(min-width:800px)");
  const [snak_open, setsnak_open] = React.useState(false);
  const [snack_name, setSnackName] = React.useState("");
  const [is_lodaing_data, setLoadigData] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [current_secrtery, setCurrentSecrtery] = React.useState({
    name: "",
    image: "",
    user_id: "6047166d7d2f780840c48f8d",
    is_online: true,
  });
  const [current_user, setCurrentChatUser] = React.useState({
    name: "",
    image: "",
    user_id: "6047166d7d2f780840c48f8d",
    is_online: true,
  });
  const [text_message, setTextMessage] = React.useState("");
  const [messages, setMessages] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [messsage_to_delete, setMessageToDelete] = React.useState({
    messages: {},
    index: 0,
  });

  const [users, setusers] = React.useState([]);
  //===============Menu============\\
  const handleMenuClick = (event, x, i) => {
    setMessageToDelete({ messages: x, index: i });
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  //===============Delete Message============\\
  const handleDeleteMessage = async (message_data, index) => {
    // DELETE MESSAGE IN SOCKET
    let delete_message_obj = {
      message_id: messsage_to_delete.messages.message_id,
      senderId: localStorage.getItem("admin_id"),
      receiver_id: current_user.user_id,
    };
    socketRef.current.emit(DELETE_MESSAGE, delete_message_obj);
    // const new_message = messages.splice(messsage_to_delete.index, 1);
    const new_message_arr = [];
    messages.map((x, i) => {
      if (
        String(x.message_id) != String(messsage_to_delete.messages.message_id)
      ) {
        new_message_arr.push(x);
      }
    });
    setMessages(new_message_arr);
    setAnchorEl(null);
  };
  //===============Send Message============\\
  const sendMessage = (event) => {
    event.preventDefault();
    if (text_message == "") {
      return;
    }
    const new_message_id = uuidv4();
    let _messages = messages;
    _messages = [
      ...messages,
      {
        text_message: text_message,
        sender_id: localStorage.getItem("admin_id"),
        receiver_id: current_user.user_id,
        createdAt: moment().format("DD MMMM, hh:mmA"),
        message_id: new_message_id,
      },
    ];
    setMessages(_messages);
    // SEND MESSAGE IN SOCKET
    socketRef.current.emit(NEW_CHAT_MESSAGE_EVENT, {
      message_id: new_message_id,
      body: text_message,
      senderId: localStorage.getItem("admin_id"),
      receiver_id: current_user.user_id,
    });
    scrollToMyRef();
    setTextMessage("");
  };

  const scrollToMyRef = () => {
    chatContainer.current.addEventListener("DOMNodeInserted", (event) => {
      const { currentTarget: target } = event;
      target.scroll({ top: target.scrollHeight });
    });
  };
  const handle_Current_user = (x) => {
    console.log(x.user_id, "user id");
    setLoadigData(true);
    setMessages((mesages) => []);
    setCurrentChatUser({
      name: x.name,
      image: x.profile_image,
      user_id: String(x.user_id),
      is_online: x.is_online,
    });
    get_users();
  };

  const get_users = async () => {
    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };
    let requestObj = {
      path: "/api/patient/list_patient_by_clinic",
      method: "GET",
      headers: header,
    };
    invokeApi(requestObj).then((res) => {
      console.log(res, "CUSTOMERS");
      if (res.code == 200) {
        const patients = res.patient.map((x, i) => {
          return x.customer;
        });
        patients.sort((x, y) => y.message_unread_count - x.message_unread_count);
        console.log(patients, "patientspatientspatients");
        setusers(patients);
      }
    });
  };
  const get_user_chat = () => {
    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };
    const data = {
      receiver_id: localStorage.getItem("admin_id"),
      sender_id: current_user.user_id,
    };
    let requestObj = {
      path: "/api/chat/get_admin_chat",
      method: "POST",
      headers: header,
      postData: data,
    };
    invokeApi(requestObj).then((res) => {
      console.log(res, "RESPONSE");
      if (res.code == 200) {
        setMessages(res.Message);
        setLoadigData(false);
        scrollToMyRef();
      }
    });
  };
  ///// sacnk
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  function handle_snack_clock() {
    setsnak_open(false);
  }
  //=====================end snack==============\\

  const hanldeSearchChange = (e) => {
    setSearch(e.target.value);

    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };
    const data = {
      search_patient: e.target.value,
    };

    let requestObj = {
      path: `/api/patient/search_patient_by_clinic`,
      method: "POST",
      headers: header,
      postData: data,
    };
    if (e.target.value !== "") {
      invokeApi(requestObj).then((res) => {
        console.log(res, "SEACRH");
        if (res.code == 200) {
          const patients = res.patient.map((x, i) => {
            return x.customer_id;
          });
          setusers(patients);
        }
      });
    } else {
      get_users();
    }
  };
  const get_secrtery_detail = async () => {
    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };
    let requestObj = {
      path: `/api/clinic/detail_clinic/${localStorage.getItem("admin_id")}`,
      method: "GET",
      headers: header,
    };
    invokeApi(requestObj).then((res) => {
      console.log(res, "CLINIC");
      if (res.code == 200) {
        console.log(res, "secrtry");
        setCurrentSecrtery({
          name: res.clinic.clinic_name,
          image: res.clinic.clinic_image,
          user_id: res.clinic.user_id,
          is_online: true,
        });
      }
    });
  };

   // increment count
   const message_incremnt_count=async(user_id)=>{
    // call api
    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };
    let requestObj = {
      path: `/api/customer/add_message_count/${user_id}`,
      method: "GET",
      headers: header,
    };
    invokeApi(requestObj).then( async (res) => {
      if (res.code == 200) {
      await get_users();
      }
    });
  }
  // message increment count reset
   // increment count
   const message_incremnt_count_reset=async(user_id)=>{
    // call api
    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };
    let requestObj = {
      path: `/api/customer/reset_message_count/${user_id}`,
      method: "GET",
      headers: header,
    };
    invokeApi(requestObj).then( async (res) => {
      if (res.code == 200) {
      await get_users();
      }
    });
  }

  useEffect(() => {
    get_secrtery_detail();
  }, []);
  useEffect(() => {
    socketRef.current = socketIOClient(SOCKET_SERVER_URL, {
      query: { roomId: localStorage.getItem("admin_id") },
    });
    //=========================ON CONNECTON===============\\
    socketRef.current.on("connect", () => {
      console.log(socketRef.current.id);
    });
    //=========================USER OFFLINE===============\\
    socketRef.current.on("user_offline", async (user_id) => {
      console.log(user_id, "USER ID", current_user.user_id);
      await get_users();
      if (String(current_user.user_id) == String(user_id)) {
        setCurrentChatUser({ ...current_user, is_online: false });
      }
    });
    //=========================USER ONLINE===============\\
    socketRef.current.on("user_online", async (data) => {
      console.log(data, "USER ONLINE");
      await get_users();
      if (String(current_user.user_id) == String(data.user_id)) {
        setCurrentChatUser({ ...current_user, is_online: true });
      }
    });

    //=========================LISTEN MESSAGE===============\\
    socketRef.current.on("receiver_mg",async (data) => {
      if (String(data.sender_id) === String(current_user.user_id)) {
        //setMessages((mesgs) => [...mesgs, data]);
        get_user_chat();
        scrollToMyRef();
      } else {
        const find_index = users.findIndex(
          (item) => item.user_id == data.sender_id
        );
        if (find_index !== -1) {
          console.log(users[find_index].name, "user anme");
          setSnackName(users[find_index].name);
          setsnak_open(true);
           // increment count
           const new_user=[...users];
           users[find_index].message_unread_count+=1;
           //setusers(new_user);
           console.log(new_user);
           await message_incremnt_count(data.sender_id);
        }
      }
    });
    //=========================ON DELETE MESSAGE===============\\
    socketRef.current.on(RECEIVER_DELETE_MESSAGE, (data) => {
      const new_message = [];
      console.log(data, "DATA receive");
      get_user_chat();

      //setMessages(new_message);
    });

    return () => {
      socketRef.current.disconnect();
    };
  }, [current_user]);

  useEffect(() => {
    get_users();
  }, []);
  useEffect(() => {
    get_user_chat();
  }, [current_user]);
  return (
    <div>
      <Container maxWidth="lg">
        <Typography variant="h6" style={{ paddingBottom: 20 }}>
          Chat
        </Typography>
        <Paper elevation={0}>
          <Grid container spacing={3}>
            <Grid
              item
              xs={matches == true ? 3 : 4}
              className={classes.userList}
            >
              <List
                component="nav"
                aria-label="main mailbox folders"
                className={classes.stick_list}
              >
                <ListItem style={{ cursor: "pointer" }}>
                  <StyledBadgeonline
                    overlap="circle"
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    variant="dot"
                  >
                    <Avatar
                      alt="Remy Sharp"
                      style={{ width: 50, height: 50 }}
                      src={image_url + current_secrtery.image}
                    />
                  </StyledBadgeonline>
                  <List style={{ marginLeft: 20 }}>
                    <Typography vvariant="subtitle1" style={{ marginTop: 10 }}>
                      {current_secrtery.name}
                    </Typography>

                    <Typography
                      variant="caption"
                      style={{ color: "rgb(0 0 0 / 80%)" }}
                    >
                      Online
                    </Typography>
                  </List>
                </ListItem>
                <TextField
                  label="Search User"
                  className={classes.root}
                  fullWidth
                  value={search}
                  onChange={hanldeSearchChange}
                  id="outlined-size-small"
                  defaultValue=""
                  variant="outlined"
                  size="small"
                />
                {/* <Divider /> */}
              </List>
              <List component="nav" aria-label="main mailbox folders">
                {users.map((x, i) => {
                  return (
                    <ListItem
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handle_Current_user(x);
                        message_incremnt_count_reset(x.user_id);
                        x.message_incremnt_count=0;
                      }}
                    >
                       { x.message_unread_count>0? 
                          <Badge style={{marginLeft:0}} badgeContent={x.message_unread_count} color="primary">
                          </Badge>: null
                          }
                      {x.is_online == true ? (
                        <StyledBadgeonline
                          overlap="circle"
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          color="primary"
                          variant="dot"
                        >
                          <Badge badgeContent={x.count} color="primary">
                            <Avatar
                              alt="Remy Sharp"
                              style={{ width: 50, height: 50 }}
                              src={
                                x.profile_image !== ""
                                  ? image_url + x.profile_image
                                  : null
                              }
                            />
                          </Badge>
                        </StyledBadgeonline>
                      ) : (
                        <StyledBadgeofline
                          overlap="circle"
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          color="primary"
                          variant="dot"
                        >
                          <Badge badgeContent={x.count} color="primary">
                            <Avatar
                              alt="Remy Sharp"
                              style={{ width: 50, height: 50 }}
                              src={
                                x.profile_image !== ""
                                  ? image_url + x.profile_image
                                  : null
                              }
                            />
                          </Badge>
                        </StyledBadgeofline>
                      )}
                      <List style={{ marginLeft: 20 }}>
                        <Typography
                          vvariant="subtitle1"
                          style={{ marginTop: 10 }}
                        >
                          {x.name}
                        </Typography>

                        <Typography
                          variant="caption"
                          style={{ color: "rgb(0 0 0 / 80%)" }}
                        >
                          {x.is_online == true ? (
                            <>Online</>
                          ) : x.is_online == false ? (
                            <>Offline</>
                          ) : null}
                        </Typography>
                      </List>
                    </ListItem>
                  );
                })}
              </List>
            </Grid>
            <Grid item xs={matches == true ? 9 : 8}>
              <div ref={chatContainer} className={classes.message_list}>
                <List
                  component="nav"
                  aria-label="main mailbox folders"
                  className={classes.stick_list}
                >
                  <ListItem style={{ cursor: "pointer" }}>
                    {current_user.is_online == true ? (
                      <StyledBadgeonline
                        overlap="circle"
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        variant="dot"
                      >
                        <Avatar
                          alt="Remy Sharp"
                          style={{ width: 50, height: 50 }}
                          src={
                            current_user.image !== ""
                              ? image_url + current_user.image
                              : null
                          }
                        />
                      </StyledBadgeonline>
                    ) : (
                      <StyledBadgeofline
                        overlap="circle"
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        variant="dot"
                      >
                        <Avatar
                          alt="Remy Sharp"
                          style={{ width: 50, height: 50 }}
                          src={
                            current_user.image !== ""
                              ? image_url + current_user.image
                              : null
                          }
                        />
                      </StyledBadgeofline>
                    )}
                    <List style={{ marginLeft: 20 }}>
                      <Typography variant="subtitle1" style={{ marginTop: 10 }}>
                        {current_user.name}
                      </Typography>

                      <Typography
                        variant="caption"
                        style={{ color: "rgb(0 0 0 / 80%)" }}
                      >
                        {current_user.is_online == true ? (
                          <>Online</>
                        ) : current_user.is_online == false ? (
                          <>Offline</>
                        ) : null}
                      </Typography>
                    </List>
                  </ListItem>
                  <Divider />
                </List>
                {is_lodaing_data == true ? (
                  // <CircularProgress
                  //   style={{
                  //     marginLeft: "48%",
                  //     marginTop: 120,
                  //     marginBottom: 150,
                  //   }}
                  // />
                  <div
                    style={{
                      marginLeft: "48%",
                      marginTop: "30%",
                      marginBottom: 150,
                    }}
                  ></div>
                ) : (
                  <List style={{ minHeight: 300 }}>
                    {messages.map((x, i) => {
                      return (
                        <>
                          <Box
                            display="flex"
                            flexDirection={
                              x.receiver_id == current_user.user_id
                                ? "row-reverse"
                                : null
                            }
                          >
                            {x.receiver_id == current_user.user_id ? (
                              <div>
                                <MoreVertIcon
                                  aria-controls="simple-menu"
                                  aria-haspopup="true"
                                  style={{
                                    cursor: "pointer",
                                  }}
                                  onClick={(e) => handleMenuClick(e, x, i)}
                                ></MoreVertIcon>

                                <Menu
                                  id="simple-menu"
                                  anchorEl={anchorEl}
                                  keepMounted
                                  open={Boolean(anchorEl)}
                                  onClose={handleMenuClose}
                                >
                                  <MenuItem
                                    onClick={() => handleDeleteMessage(x, i)}
                                  >
                                    Delete Message
                                  </MenuItem>
                                </Menu>
                              </div>
                            ) : null}
                            <Grid item xs={5} style={{ marginLeft: 20 }}>
                              <Paper
                                className={
                                  x.receiver_id == current_user.user_id
                                    ? classes.message
                                    : classes.simple_message
                                }
                              >
                                <Typography
                                  variant="subtitle2"
                                  style={{ marginLeft: 10 }}
                                >
                                  {x.text_message}
                                </Typography>
                              </Paper>
                              <Typography
                                variant="body2"
                                style={{
                                  marginLeft: 10,
                                  marginTop: -25,
                                  paddingBottom: 10,
                                  color: "#808080",
                                  fontSize: 11,
                                }}
                              >
                                {x.createdAt}
                              </Typography>
                            </Grid>
                          </Box>
                        </>
                      );
                    })}
                  </List>
                )}
              </div>
              <form onSubmit={sendMessage}>
                <Box display="flex" style={{ paddingTop: 20 }}>
                  <TextField
                    label="Type Message"
                    className={classes.root}
                    fullWidth
                    value={text_message}
                    onChange={(e) => setTextMessage(e.target.value)}
                    id="outlined-size-small"
                    defaultValue=""
                    variant="outlined"
                    size="small"
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={text_message == "" ? true : false}
                    className={classes.button}
                    onClick={sendMessage}
                    endIcon={<SendIcon />}
                  >
                    Send
                  </Button>
                </Box>
              </form>
            </Grid>
          </Grid>
        </Paper>
      </Container>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={snak_open}
        autoHideDuration={6000}
        onClose={handle_snack_clock}
      >
        <Alert onClose={handle_snack_clock} severity="success">
          You received new message from {snack_name}
        </Alert>
      </Snackbar>
    </div>
  );
}
