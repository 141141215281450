import React, { Component } from "react";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import dashboard from "../dashboard/dashboard";
import Updatepassword from "../updatepassword/Updatepassword";
import { Chat } from "../chat/index";

import {
  Listappointment,
  Addappointment,
  Editappointment,
  AppointmentDetail
} from "../appointments/index";

function HomePage() {
  return (
    <>
      <Switch>
        <Redirect exact from="/postit" to="/postit/dashboard" />
        <Route path="/postit/chat" component={Chat} />
        <Route path="/postit/dashboard" component={dashboard} />
        <Route path="/postit/updatepassword" component={Updatepassword} />

        <Route path="/postit/appointment" component={Listappointment} />
        <Route path="/postit/add-appointment" component={Addappointment} />
        <Route path="/postit/edit-appointment" component={Editappointment} />
        <Route path="/postit/detail-appoitment/:id" component={AppointmentDetail}/>

      </Switch>
    </>
  );
}

export default withRouter(HomePage);
