import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { invokeApi } from "../../../bl_libs/ApiCalling";
import { Grid, Card, Divider } from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(() => ({
  root: {
    width: 700,
    margin: "auto",
  },
}));
export default function AppointmentDetail(props) {
  const classes = useStyles();
  const history = useHistory();
  const [data, setDetail] = useState("");
  const [laoding, setLaoding] = useState(true);
  const handleback = () => {
    history.goBack();
  };
  const get_detail = async () => {
    const header = {
      "x-sh-auth": localStorage.getItem("token"),
    };
    let requestObj = {
      path: `/api/appointment/detail_appointment/${props.match.params.id}`,
      method: "GET",
      headers: header,
    };
    invokeApi(requestObj).then((res) => {
      console.log(res, "RESPONSE");
      if (res.code == 200) {
        setDetail(res.appointment);
        setLaoding(false);
      }
    });
  };
  useEffect(async () => {
    await get_detail();
  }, []);

  if (laoding == true) {
    return (
      <>
        <CircularProgress style={{ marginTop: "15%", marginLeft: "45%" }} />
      </>
    );
  }
  return (
    <>
      <ArrowBackIcon
        style={{
          cursor: "pointer",
          marginLeft: "200px",
        }}
        onClick={handleback}
      />
      <Card className={classes.root}>
        <CardContent className={classes.content}>
          <Typography
            gutterBottom
            variant="h5"
            component="h2"
            style={{ textAlign: "center" }}
          >
            Appoitment Detail
          </Typography>
          <Grid container>
            <Grid item xs={3}>
              <Typography
                variant="body2"
                color="textSecondary"
                component="b"
                style={{}}
              >
                <b>Title</b>
              </Typography>
            </Grid>
            <Grid item xs={9}>
              {data.title ? <>{data.title}</> : <>N / A</>}
            </Grid>
            <Grid item xs={12}>
              <Divider style={{ marginTop: 10, marginBottom: 10 }} />
            </Grid>
          </Grid>
          {/* ============DIVIDER================== */}
          <Grid container>
            <Grid item xs={3}>
              <Typography
                variant="body2"
                color="textSecondary"
                component="b"
                style={{}}
              >
                <b>Appointer Name</b>
              </Typography>
            </Grid>
            <Grid item xs={9}>
              {data.appointter_name ? <>{data.appointter_name}</> : <>N / A</>}
            </Grid>
            <Grid item xs={12}>
              <Divider style={{ marginTop: 10, marginBottom: 10 }} />
            </Grid>
          </Grid>
          {/* ============DIVIDER================== */}
          <Grid container>
            <Grid item xs={3}>
              <Typography
                variant="body2"
                color="textSecondary"
                component="b"
                style={{}}
              >
                <b>Patient Name</b>
              </Typography>
            </Grid>
            <Grid item xs={9}>
              {data.patient_name ? <>{data.patient_name}</> : <>N / A</>}
            </Grid>
            <Grid item xs={12}>
              <Divider style={{ marginTop: 10, marginBottom: 10 }} />
            </Grid>
          </Grid>
          {/* ============DIVIDER================== */}
          <Grid container>
            <Grid item xs={3}>
              <Typography
                variant="body2"
                color="textSecondary"
                component="b"
                style={{}}
              >
                <b>Description</b>
              </Typography>
            </Grid>
            <Grid item xs={9}>
              {data.description ? <>{data.description}</> : <>N / A</>}
            </Grid>
            <Grid item xs={12}>
              <Divider style={{ marginTop: 10, marginBottom: 10 }} />
            </Grid>
          </Grid>
          {/* ============DIVIDER================== */}
          <Grid container>
            <Grid item xs={3}>
              <Typography
                variant="body2"
                color="textSecondary"
                component="b"
                style={{}}
              >
                <b>Status</b>
              </Typography>
            </Grid>
            <Grid item xs={9}>
              {data.status ? (
                <>
                  {data.appointment_status == 0 ? (
                    <>Pending approval </>
                  ) : data.appointment_status == 1 ? (
                    <>Appointment Cancel by user</>
                  ) : data.appointment_status == 2 ? (
                    <>Reschedule Request </>
                  ) : data.appointment_status == 3 ? (
                    <>Reschedule Request accpeted</>
                  ) : data.appointment_status == 4 ? (
                    <>Reschedule Request cancel</>
                  ) : data.appointment_status == 5 ? (
                    <>Appointment confirm</>
                  ) : data.appointment_status == 6 ? (
                    <>Reschedule Request rejected</>
                  ) : data.appointment_status == 7 ? (
                    <>Appointment cancel by admin</>
                  ) : (
                    <>Nill</>
                  )}
                </>
              ) : (
                <>N / A</>
              )}
            </Grid>
            <Grid item xs={12}>
              <Divider style={{ marginTop: 10, marginBottom: 10 }} />
            </Grid>
          </Grid>
          {/* ============DIVIDER================== */}
          <Grid container>
            <Grid item xs={3}>
              <Typography
                variant="body2"
                color="textSecondary"
                component="b"
                style={{}}
              >
                <b>Appointment Date</b>
              </Typography>
            </Grid>
            <Grid item xs={9}>
              {data.appointment_date_time ? (
                <>{data.appointment_date_time}</>
              ) : (
                <>N / A</>
              )}
            </Grid>
            <Grid item xs={12}>
              <Divider style={{ marginTop: 10, marginBottom: 10 }} />
            </Grid>
          </Grid>
          {/* ============DIVIDER================== */}
          <Grid container>
            <Grid item xs={3}>
              <Typography
                variant="body2"
                color="textSecondary"
                component="b"
                style={{}}
              >
                <b>Reschedule Date</b>
              </Typography>
            </Grid>
            <Grid item xs={9}>
              {data.reschedule_date ? (
                <>{data.reschedule_date}</>
              ) : (
                <>Reschedule date not found.</>
              )}
            </Grid>
            <Grid item xs={12}>
              <Divider style={{ marginTop: 10, marginBottom: 10 }} />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={3}>
              <Typography
                variant="body2"
                color="textSecondary"
                component="b"
                style={{}}
              >
                <b>Requested Reschedule Date</b>
              </Typography>
            </Grid>
            <Grid item xs={9}>
              {console.log(data.rescheduled_request_date_time, "time---------")}

              {data.rescheduled_request_date_time.length > 0 ? (
                <p style={{ marginTop: 0 }}>
                  {data.rescheduled_request_date_time}
                </p>
              ) : (
                <p style={{ marginTop: 0 }}>
                  Requested reschedule date not found
                </p>
              )}
            </Grid>
            <Grid item xs={12}>
              <Divider style={{ marginTop: 10, marginBottom: 10 }} />
            </Grid>
          </Grid>
          {/* ============DIVIDER================== */}
          <Grid container>
            <Grid item xs={3}>
              <Typography
                variant="body2"
                color="textSecondary"
                component="b"
                style={{}}
              >
                <b>Reschedule Note</b>
              </Typography>
            </Grid>
            <Grid item xs={9}>
              {data.reschedule_note ? (
                <>{data.reschedule_note}</>
              ) : (
                <>Data not found.</>
              )}
            </Grid>
            <Grid item xs={12}>
              <Divider style={{ marginTop: 10, marginBottom: 10 }} />
            </Grid>
          </Grid>
          {/* ============DIVIDER================== */}
          <Grid container>
            <Grid item xs={3}>
              <Typography
                variant="body2"
                color="textSecondary"
                component="b"
                style={{}}
              >
                <b>Appointer Name</b>
              </Typography>
            </Grid>
            <Grid item xs={9}>
              {data.appointter_name ? <>{data.appointter_name}</> : <>N / A</>}
            </Grid>
            <Grid item xs={12}>
              <Divider style={{ marginTop: 10, marginBottom: 10 }} />
            </Grid>
          </Grid>
          {/* ============DIVIDER================== */}
          <Grid container>
            <Grid item xs={3}>
              <Typography
                variant="body2"
                color="textSecondary"
                component="b"
                style={{}}
              >
                <b>Cancel Note</b>
              </Typography>
            </Grid>
            <Grid item xs={9}>
              {data.cancel_note ? (
                <>{data.cancel_note}</>
              ) : (
                <>Data not found.</>
              )}
            </Grid>
            <Grid item xs={12}>
              <Divider style={{ marginTop: 10, marginBottom: 10 }} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}
