import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import { withRouter } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import { invokeApi } from "../../bl_libs/ApiCalling";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Post it{" "}
      </Link>
      {new Date().getFullYear()}
    </Typography>
  );
}

const useStyles = makeStyles(() => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "25%",
  },
  avatar: {
    backgroundColor: "#d8d433",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: "2%",
  },
  submit: {
    marginTop: "3%",
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "#d8d433",
    },
    fontSize: "15px",
  },
  txt: {
    color: "black",
    marginTop: "4%",
  },
  alert: {
    width: "100%",
  },
}));

function SignIn(props) {
  const classes = useStyles();
  const [username, setusername] = useState("");
  const [password, setpassword] = useState("");
  const [errorflag, setflag] = useState("");
  const [iswaiting, setiswaiting] = useState(false);

  const handlesubmit = (event) => {
    event.preventDefault();
    setiswaiting(true);
    let Data = {
      email: username,
      password: password,
      type: "3",
      device_token: "123",
      device_platform: "IOS",
      time_zone: "12",
    };
    let requestObj = {
      path: "/api/app_api/sign_in",
      method: "POST",
      postData: Data,
    };
    invokeApi(requestObj).then((res) => {
      setiswaiting(true);
      if (res.code == 200) {
        setiswaiting(false);
        console.log(res.User.token, "is this token?");
        localStorage.setItem("token", res.User.token);
        localStorage.setItem("admin_id", res.User._id);
        props.history.push("/postit/dashboard");
      } else {
        console.log(res, "Reponse");
        setiswaiting(false);
        setflag("invalid username or password");
      }
    });
  };
  const handleusername = (event) => {
    setusername(event.target.value);
  };
  const handlepassword = (event) => {
    setpassword(event.target.value);
  };
  if (iswaiting) {
    return <CircularProgress className="spinner" color="primary" />;
  }
  return (
    <>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography className={classes.txt} component="h1" variant="h5">
            Clinic Sign in
          </Typography>
          {errorflag && (
            <Alert className={classes.alert} severity="error">
              {errorflag}
            </Alert>
          )}
          <form className={classes.form} onSubmit={handlesubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              onChange={handleusername}
              label="Email"
              name="email"
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              onChange={handlepassword}
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              style={{
                fontSize: "15px",
              }}
              color="primary"
              className={classes.submit}
            >
              Sign In
            </Button>
          </form>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    </>
  );
}
export default withRouter(SignIn);
