import React, { Component } from "react";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import Routes from "./Routes";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { SnackbarProvider } from "notistack";
import CloseIcon from "@material-ui/icons/Close";
import { Slide } from "@material-ui/core";

const browserHistory = createBrowserHistory();

export default class App extends Component {
  render() {
    const theme = createMuiTheme({
      palette: {
        primary: {
          // Purple and green play nicely together.
          main: "#d8d433",
        },
      },
      typography: {
        fontFamily: ["Raleway"].join(","),
      },
    });
    const notiStackRef = React.createRef();
    const onClickDismiss = (key) => () => {
      notiStackRef.current.closeSnackbar(key);
    };
    return (
      <ThemeProvider theme={theme}>
        <Router history={browserHistory}>
          <SnackbarProvider
            hideIconVariant
            ref={notiStackRef}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            TransitionComponent={Slide}
            maxSnack={3}
            autoHideDuration={3000}
            action={(key) => (
              <CloseIcon onClick={onClickDismiss(key)} htmlColor="white" />
            )}
          >
            <Routes />
          </SnackbarProvider>
        </Router>
      </ThemeProvider>
    );
  }
}
